import React from 'react';
import {NavLink} from 'react-router-dom';

const blogs = [
	{
		image: require('./../../images/home/homeApp.jpg'),
		title: '(주)산지 소프트웨어 (App & Web)',
		date: 'February 21, 2021',
		comments: 'Sanji App(IOS, Android), Web Dashboard'
	},
	{
		image: require('./../../images/home/homeCloud.jpg'),
		title: '(주)산지 클라우드 & IoT 허브',
		date: 'February 15, 2021',
		comments: 'Sanji Cloud & IoT Hub'
	},
	{
		image: require('./../../images/home/homeSecurity.jpg'),
		title: '(주)산지 보안 & 데이터 분석',
		date: 'February 10, 2021',
		comments: 'Sanji Security & Data Analysis',
	}
]

class Blog4 extends React.Component {
	render() {
		return (
			<>
				<div className="section-full  p-t80  bg-white blog-post-outer-3 ">
					<div className="container">
						<div className="wt-separator-two-part">
							<div className="row wt-separator-two-part-row">
								<div className="col-lg-8 col-md-7 wt-separator-two-part-left">
									{/* TITLE START*/}
									<div className="section-head left wt-small-separator-outer">
										<div className="wt-small-separator site-text-primary">
											<div className="sep-leaf-left"/>
											<div style={{fontFamily: '"Teko"'}}>WHY (주)산지?</div>
											<div className="sep-leaf-right"/>
										</div>
										<h3>다양한 산업분야의 IoT 플랫폼 솔루션 운영 경험</h3>
										<h5 style={{whiteSpace: 'pre-line', lineHeight: 1.5}}>{`스마트 도시/공장 및 맞춤형 홈 IoT 등으로 안정적 구동 중`}</h5>
									</div>
									{/* TITLE END*/}
								</div>
								{/*<div className="col-lg-4 col-md-5 wt-separator-two-part-right text-right">*/}
								{/*    <NavLink to={"/blog-grid"} className="site-button site-btn-effect">More Detail</NavLink>*/}
								{/*</div>*/}
							</div>
						</div>
						{/* BLOG SECTION START */}
						<div className="section-content">
							<div className="row d-flex justify-content-center">
								{blogs.map((item, index) => (
									<div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b30">
										<div className="blog-post date-style-2">
											<div className="wt-post-media wt-img-effect zoom-slow">
												{/*<NavLink to={"/blog-post"}><img src={item.image.default} alt=""/></NavLink>*/}
												<img src={item.image.default} alt="" loading="lazy"/>
											</div>
											<div className="wt-post-info bg-white p-t30">
												<div className="wt-post-meta ">
												    <ul>
												        {/*<li className="post-date">{item.date}</li>*/}
												        <li className="post-comment" style={{fontFamily: '"Teko"'}}>{item.comments}</li>
												    </ul>
												</div>
												<div className="wt-post-title ">
													<h4 className="post-title">{item.title}</h4>
												</div>
												{/*<div className="wt-post-readmore ">*/}
												{/*    <NavLink to={"/blog-post"} className="site-button-link black">Read More</NavLink>*/}
												{/*</div>*/}
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
};

export default Blog4;
