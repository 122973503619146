import React from 'react';
import {NavLink} from 'react-router-dom';

const services = [
	{
		image: require('./../../images/uplink/uplinkHub.png'),
		title: 'IoT허브',
		description: '근거리 무선통신 기기를 위한 (주)산지 IoT 허브 제공'
	},
	{
		image: require('./../../images/uplink/software.jpg'),
		title: '디바이스 연결 & 관리',
		description: '기기의 IoT 플랫폼 연결, 모니터링, 제어 등을 지원'
	},
	{
		image: require('./../../images/uplink/dataReport.jpg'),
		title: '스마트 데이터 리포트',
		description: '기간별로 쉽게 확인이 가능한 데이터 분석 도구'
	},
	{
		image: require('./../../images/uplink/iotEdu.jpg'),
		title: '스마트 씬',
		description: '다양한 상황별 이벤트 대응 및 자동 제어 기능'
	},
	{
		image: require('./../../images/uplink/dataManage.jpg'),
		title: '데이터 보안 관리',
		description: '별도의 데이터 서버 운용'
	},
	{
		image: require('./../../images/uplink/userFit.jpg'),
		title: '맞춤형 커스터마이징',
		description: '사용자 맞춤형 플랫폼 제작'
	}
]

const ServicesUplink = () => {
	
	return (
		<>
			<div className="section-full p-t80 p-b40">
				<div className="container">
					<div className="section-content">
						<div className="wt-separator-two-part">
							<div className="row wt-separator-two-part-row">
								<div className="col-lg-8 col-md-7 wt-separator-two-part-left">
									{/* TITLE START*/}
									<div className="section-head left wt-small-separator-outer">
										<div className="wt-small-separator site-text-primary">
											<div className="sep-leaf-left"/>
											<div style={{fontSize: '25px', fontWeight: '600'}}>주요 기능</div>
											<div className="sep-leaf-right"/>
										</div>
										<h3>UPLINK+ 주요 기능 소개</h3>
									</div>
									{/* TITLE END*/}
								</div>
								{/*<div className="col-lg-4 col-md-5 wt-separator-two-part-right text-right">*/}
								{/*	<NavLink to={"/about-1"} className="site-button site-btn-effect">More Detail</NavLink>*/}
								{/*</div>*/}
							</div>
						</div>
						<div className="row d-flex justify-content-center">
							{services.map((item, index) => (
								<div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b50">
									<div className="service-border-box service-image-box">
										<div className="wt-box service-box-1 bg-white">
											<div className="service-box-1-media m-b20">
												<img src={item.image.default} alt="" loading="lazy"/>
											</div>
											<div className="service-box-title title-style-2 site-text-secondry" >
												<span className="s-title-one" style={{fontSize: '22px', fontWeight: '500'}}>{item.title}</span>
												<span className="s-title-two"/>
											</div>
											<div className="service-box-content">
												<p style={{fontSize: 14}}>{item.description}</p>
												{/*<NavLink to={"/services-mechanical"} className="site-button-link">Read More</NavLink>*/}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
	
};

export default ServicesUplink;
