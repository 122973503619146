import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import {LazyLoadImage} from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/opacity.css';

const testimonials = [
    {
        image: require('./../../images/sion/testimonials1.jpg'),
        reviewername: 'Malcolm Franzcrip',
        position: 'Contractor',
        review: 'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!'
    },
    {
        image: require('./../../images/sion/testimonials2.jpg'),
        reviewername: 'Malcolm Franzcrip',
        position: 'Contractor',
        review: 'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!'
    },
    // {
    //     image: require('./../../images/testimonials/pic3.jpg'),
    //     reviewername: 'Malcolm Franzcrip',
    //     position: 'Contractor',
    //     review: 'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!'
    // },
    // {
    //     image: require('./../../images/testimonials/pic4.jpg'),
    //     reviewername: 'Malcolm Franzcrip',
    //     position: 'Contractor',
    //     review: 'This is not just another nail salon! These ladies are super talented! My nails have never looked/felt more amazing!! the environment here is so happy and cheery!'
    // }
]


var bnr1 = require('./../../images/background/bg-map.png');

class Testimonials11 extends React.Component {
    render() {
        const options = {
            loop: false,
            autoplay: false,
            nav: false,
            dots: false,
            margin: 30,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                },
                991: {
                    items: 1,
                },
                1400: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full  p-t80 p-b50 bg-no-repeat bg-center bg-gray" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer">
                            <div className="wt-small-separator site-text-primary">
                                <div className="sep-leaf-left" />
                                <div style={{fontSize: '28px', fontWeight: '600'}}>시온의 역할</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h4>사회 전반에 걸쳐 증가하고 있는 노인 / 장애인의 자립을 IoT 솔루션으로 지원</h4>
                        </div>
                        {/* TITLE END*/}
                        <div className="testimonial-2-content-outer">
                            <OwlCarousel className="testimonial-3-content owl-carousel  owl-btn-vertical-center long-arrow-next-prev" {...options}>
                                {testimonials.map((item, index) => (
                                    <div key={index} className="item">
                                         <img src={item.image.default} alt="" loading="lazy" />
                                        {/*<div className="testimonial-2 bg-white">*/}
                                        {/*    <div className="testimonial-content">*/}
                                        {/*        <img src={item.image.default} alt="" />*/}
                                        {/*        <div className="testimonial-text">*/}
                                        {/*            <i className="fa fa-quote-left" />*/}
                                        {/*            <p>{item.review}</p>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="testimonial-detail clearfix">*/}
                                        {/*            <div className="testimonial-pic-block">*/}
                                        {/*                <div className="testimonial-pic">*/}
                                        {/*                    <img src={item.image.default} alt="" />*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="testimonial-info">*/}
                                        {/*                <span className="testimonial-name  title-style-2 site-text-secondry">{item.reviewername}</span>*/}
                                        {/*                <span className="testimonial-position title-style-2 site-text-primary">{item.position}</span>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials11;
