import React from 'react';
import { NavLink } from 'react-router-dom';

const teamMembers = [
    {
        image: require('./../../images/home/homeEasy.jpg'),
        membername: 'EASY',
        position: '쉽고 간단한 구축 & 운영'
    },
    {
        image: require('./../../images/home/homeLow.jpg'),
        membername: 'LOW',
        position: '효율적인 비용 구조 체계'
    },
    {
        image: require('./../../images/home/homeFlex.jpg'),
        membername: 'FLEXIBLE',
        position: '유연하고 확장성 있는 서비스'
    }
]

let bnr1 = require('./../../images/background/bg-map2.png');

class Team1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white team-bg-section-outer">
                    <div className="team-bg-map site-bg-secondry bg-repeat-x" style={{ backgroundImage: "url(" + bnr1.default + ")" }}/>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div style={{fontSize: '28px', fontWeight: '600', fontFamily: '"Teko"'}}>(주)산지</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                {/*<h4 style={{whiteSpace: 'pre-line'}}>{'우리는 IoT 플랫폼으로 다양한 산업분야의 IoT 연결을 지원하고\n' +*/}
                                {/*'노인,장애인의 가정 생활 보조를 위한 홈 IoT 서비스를 제공합니다.'}</h4>*/}
                            </div>
                            {/* TITLE END*/}
                            <div className="section-content">
                                <div className="row justify-content-center">
                                    {teamMembers.map((item, index) => (
                                        <div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b30">
                                            <div className="wt-team-1">
                                                <div className="wt-media">
                                                    <img src={item.image.default} alt="" loading="lazy" />
                                                    {/*<div className="team-social-center">*/}
                                                    {/*    <ul className="team-social-bar">*/}
                                                    {/*        <li><NavLink to={"#"}><i className="fa fa-facebook" /></NavLink></li>*/}
                                                    {/*        <li><NavLink to={"#"}><i className="fa fa-twitter" /></NavLink></li>*/}
                                                    {/*        <li><NavLink to={"#"}><i className="fa fa-instagram" /></NavLink></li>*/}
                                                    {/*        <li><NavLink to={"#"}><i className="fa fa-linkedin" /></NavLink></li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="wt-info p-a30  p-b0">
                                                    <div className="team-detail  text-center">
                                                        <h3 className="m-t0 team-name">
                                                            <span className=" site-text-white" style={{fontWeight: '700', fontFamily: '"Teko"'}}>
                                                                {item.membername}
                                                            </span>
                                                        </h3>
                                                        <span className="title-style-2 team-position site-text-primary" style={{fontSize: '22px', fontWeight: '700'}}>{item.position}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Team1;
