import React from 'react';
import {NavLink} from 'react-router-dom';
import Switcher from '../Segments/Switcher';

let bgimage = require('./../../images/background/footer-bg.png');

class Footer extends React.Component {
	constructor(props) {
		super(props);
		
		let homepage4 = /\/home-transport/i;
		let homepage5 = /\/home-agriculture/i;
		
		if (homepage4.test(window.location.href)) {
			this.currentstate = {logo: require('./../../images/logo-2-light.png').default};
		} else if (homepage5.test(window.location.href)) {
			this.currentstate = {logo: require('./../../images/logo-4-light.png').default};
		} else {
			this.currentstate = {logo: require('./../../images/uplinkLogo/leftWhiteLogo.png').default};
		}
		
	}
	
	updateFooterLogo = (updatedlogo) => {
		this.currentstate.logo = updatedlogo.default;
	}
	
	render() {
		
		return (
			<>
				<footer className="site-footer footer-large footer-dark text-white footer-style1">
					{/* FOOTER BLOCKES START */}
					<div className="footer-top bg-no-repeat bg-bottom-right"
					     style={{backgroundImage: 'url(' + bgimage.default + ')'}}>
						<div className="container">
							<div className="row">
								<div className="col-lg-5 col-md-12 col-sm-12">
									<div className="footer-h-left">
										<div className="widget widget_about">
											<div className="logo-footer clearfix">
												<NavLink to={"./"}><img src={this.currentstate.logo} alt=""/></NavLink>
											</div>
											<p style={{whiteSpace: 'pre-line'}}>
												{'데이터와 세상을 연결하는 공간 \n' +
												'따뜻한 시선으로 세상과 소통하는 데이터를 만들고 \n' +
												'데이터와 세상을 연결해주는 기업 (주)산지 입니다.'}
											</p>
												{/*<img src={require('./../../images/ventureAuthLogo.png').default} alt="" loading="lazy" style={{width: 120, height: 70}}/>*/}
										</div>
										{/*<div className="widget recent-posts-entry">*/}
										{/*	<ul className="widget_address">*/}
										{/*		<li><i className="fa fa-map-marker"/>경기도 고양시 일산동구 동국로 32, 626호</li>*/}
										{/*		<li><i className="fa fa-envelope"/>info@uplink.co.kr</li>*/}
										{/*		<li><i className="fa fa-phone"/>(031) 962-1077</li>*/}
										{/*	</ul>*/}
										{/*</div>*/}
										{/*<ul className="social-icons  wt-social-links footer-social-icon">*/}
										{/*	<li><NavLink to={"#"} className="fa fa-home"/></li>*/}
										{/*	<li><NavLink to={"#"} className="fa fa-facebook"/></li>*/}
										{/*	<li><NavLink to={"#"} className="fa fa-square"/></li>*/}
										{/*	<li><NavLink to={"#"} className="fa fa-instagram"/></li>*/}
										{/*	<li><NavLink to={"#"} className="fa fa-youtube"/></li>*/}
										{/*</ul>*/}
									</div>
								</div>
								<div className="col-lg-7 col-md-12 col-sm-12">
									<div className="row footer-h-right">
										<div className="col-lg-5 col-md-4">
											<div className="widget widget_services">
												<h3 className="widget-title" style={{fontFamily: '"Teko"'}}>Useful links</h3>
												<ul>
													<li><NavLink to={"/"}>Home</NavLink></li>
													<li><NavLink to={"/uplinkplus"}>Uplink+</NavLink></li>
													<li><NavLink to={"/sion"}>Sion</NavLink></li>
													<li><NavLink to={"/project-grid"}>Project</NavLink></li>
													<li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
												</ul>
											</div>
										</div>
										<div className="widget recent-posts-entry">
											<ul className="widget_address">
												<li style={{whiteSpace: 'pre-line'}}><i className="fa fa-map-marker"/>{'(10874) 경기도 파주시 청석로 272 (동패동) 1004호'}</li>
												<li><i className="fa fa-envelope"/>sung@hillcountry.kr</li>
												<li><i className="fa fa-phone"/>(070)8983-8927</li>
											</ul>
											{/*<ul className="social-icons  wt-social-links footer-social-icon" style={{marginTop: 30}}>*/}
											{/*	<li><NavLink to={"/"} className="fa fa-home"/></li>*/}
											{/*	<li><a href={'https://www.facebook.com/uplinkplus'} className="fa fa-facebook"/></li>*/}
											{/*	/!*<li><NavLink to={"#"} className="fa fa-square"/></li>*!/*/}
											{/*	<li><a href={"https://www.instagram.com/sion_plus/?hl=ko"} className="fa fa-instagram"/></li>*/}
											{/*	<li><a href={"https://www.youtube.com/channel/UCsXgx0YhWwiAppqMzLXwD0A"}*/}
											{/*	       className="fa fa-youtube"/></li>*/}
											{/*</ul>*/}
										</div>
										{/*<div className="col-lg-7 col-md-8">*/}
										{/*	<div className="widget widget_services">*/}
										{/*		<h3 className="widget-title">Our Services</h3>*/}
										{/*		<ul>*/}
										{/*			<li><NavLink to={"/services-oilgas"}>Oil &amp; Gas Factory</NavLink><NavLink*/}
										{/*				to={"/services-chemical"}>Chemical Research</NavLink></li>*/}
										{/*			<li><NavLink to={"/services-chemical"}>Chemical Research</NavLink><NavLink*/}
										{/*				to={"/services-agricultural"}>Agricultural</NavLink></li>*/}
										{/*			<li><NavLink to={"/services-mechanical"}>Mechanical</NavLink><NavLink*/}
										{/*				to={"/services-agricultural"}>Agricultural</NavLink></li>*/}
										{/*			<li><NavLink to={"/services-automotive"}>Manufacturing</NavLink><NavLink*/}
										{/*				to={"/services-civil"}>Civil Engineering</NavLink></li>*/}
										{/*			<li><NavLink to={"/services-mechanical"}>Mechanical</NavLink><NavLink*/}
										{/*				to={"/services-automotive"}>Automotive</NavLink></li>*/}
										{/*		</ul>*/}
										{/*	</div>*/}
										{/*</div>*/}
									</div>
									{/*<div className="widget widget_newsletter">*/}
									{/*	<h3 className="widget-title">Newsletter</h3>*/}
									{/*	<p>Subscribe to our newsletter to receive latest news on our services.</p>*/}
									{/*	<div className="newsletter-input">*/}
									{/*		<div className="input-group">*/}
									{/*			<input id="email" type="text" className="form-control" name="email"*/}
									{/*			       placeholder="Enter your email"/>*/}
									{/*			<div className="input-group-append">*/}
									{/*				<button type="button"*/}
									{/*				        className="input-group-text nl-search-btn text-black site-bg-primary title-style-2">Subscribe*/}
									{/*				</button>*/}
									{/*			</div>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
							</div>
						</div>
					</div>
					{/* FOOTER COPYRIGHT */}
					<div className="footer-bottom">
						<div className="container">
							<div className="wt-footer-bot-left d-flex justify-content-between">
								<span className="copyrights-text" >Copyright © 2021 <span
									className="site-text-primary">(주)산지</span></span>
								{/*<ul className="copyrights-nav">*/}
								{/*	<li><NavLink to={"#"}>Terms  &amp; Condition</NavLink></li>*/}
								{/*	<li><NavLink to={"#"}>Privacy Policy</NavLink></li>*/}
								{/*	<li><NavLink to={"/contactus"}>Contact Us</NavLink></li>*/}
								{/*</ul>*/}
							</div>
						</div>
					</div>
				</footer>
				<Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/>
			
			</>
		);
	};
};

export default Footer;
