import React from 'react';
import {NavLink} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const services = [
	{
		title: '산업용 IoT 플랫폼 솔루션',
		image: require('./../../images/home/service3Business.jpg'),
	},
	{
		title: '데이터 보안 관리 및 분석',
		image: require('./../../images/home/service3Data.jpg'),
	},
	{
		title: '노인 / 장애인용 홈 IoT 솔루션',
		image: require('./../../images/home/service3Elder.jpg'),
	}
]

class Services3 extends React.Component {
	render() {
		const options = {
			loop: false,
			autoplay: true,
			nav: true,
			dots: false,
			margin: 10,
			navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
			responsive: {
				0: {
					items: 1,
				},
				640: {
					items: 1,
				},
				767: {
					items: 2,
				},
				991: {
					items: 2,
				},
				1366: {
					items: 2,
				},
				1400: {
					items: 3
				}
			}
		};
		return (
			<>
				<div className="section-full p-t80 overlay-wraper services-main-section bg-white">
					<div className="section-content services-section-content">
						<div className="row">
							<div className="col-xl-6 col-lg-12 col-md-12">
								<div className="services-section-content-left">
									{/* TITLE START*/}
									<div className="left wt-small-separator-outer">
										<div className="wt-small-separator site-text-primary">
											<div className="sep-leaf-left"/>
											<div style={{fontFamily: '"Teko"'}}>Services We Offer</div>
											<div className="sep-leaf-right"/>
										</div>
										<h3 style={{whiteSpace: 'pre-line'}}>{'(주)산지는 다양한 산업 분야에\n 맞춤형 IoT 솔루션을 제공합니다.'}</h3>
										<p>(주)산지 IoT 솔루션은 클라우드 플랫폼, 클라이언트 앱, 관리자 웹, 근거리 무선 통신 허브, 전용 IoT 컨트롤러 등을 포함합니다.</p>
										<NavLink to="/contactus" className="site-button site-btn-effect">Contact Us</NavLink>
									</div>
									{/* TITLE END*/}
								</div>
							</div>
							<div className="col-xl-6 col-lg-12 col-md-12">
								<div className="services-section-content-right2">
									<OwlCarousel className="owl-carousel services-slider owl-btn-vertical-center" {...options}>
										{services.map((item, index) => (
											<div key={index} className="item">
												<div className="wt-box bg-gray  p-a10 project-2-block">
													<div className="wt-thum-bx">
														<img src={item.image.default} alt="" loading="lazy"/>
													</div>
													<div className="wt-info">
														<h5 className="wt-title m-b0 m-t15" style={{fontSize: '18px', fontWeight: '500'}}>
															{/*<NavLink to="/services-oilgas">{item.title}</NavLink>*/}
															{item.title}
														</h5>
													</div>
												</div>
											</div>
										))}
									</OwlCarousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
};

export default Services3;
