import React from 'react';
import {NavLink} from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

class Slider4 extends React.Component {
	componentDidMount() {
		function loadScript(src) {

			return new Promise(function (resolve, reject) {
				let script = document.createElement('script');
				script.src = src;
				script.addEventListener('load', function () {
					resolve();
				});
				script.addEventListener('error', function (e) {
					reject(e);
				});
				document.body.appendChild(script);
				document.body.removeChild(script);
			})
		};

		loadScript('./assets/js/rev-script-4.js');

	};

	constructor() {
		super()
		this.state = {
			isOpen: false,
			isSionOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.openSionModal = this.openSionModal.bind(this)
	}

	openModal() {
		this.setState({isOpen: true})
	}

	openSionModal() {
		this.setState({isSionOpen: true})
	}

	render() {
		return (
			<>
				<div className="slider-outer">
					<div id="welcome_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="goodnews-header"
					     data-source="gallery" style={{background: '#eeeeee', padding: 0}}>
						<div id="welcome" className="rev_slider fullscreenbanner" style={{display: 'none'}} data-version="5.4.3.1">
							<ul>
								{/* SLIDE 1 */}
								<li data-index="rs-901" data-transition="fade" data-slotamount="default" data-hideafterloop={0}
								    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
								    data-thumb={require('./../../images/main-slider/slider4/homeMainOne.jpg').default} data-rotate={0}
								    data-fstransition="fade" data-fsmasterspeed={300} data-fsslotamount={7} data-saveperformance="off"
								    data-title="Slide Title" data-param1="Additional Text" data-param2 data-param3 data-param4
								    data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
									{/* MAIN IMAGE */}
									<img src={require('./../../images/main-slider/slider4/homeMainOne.jpg').default} alt=""
									     data-lazyload={require('./../../images/main-slider/slider4/homeMainOne.jpg').default}
									     data-bgposition="center center" data-bgfit="cover" data-bgparallax={4} className="rev-slidebg"
									     data-no-retina/>
									{/* LAYERS */}
									{/* LAYER NR. 1 [ for overlay ] */}
									<div className="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-0"
									     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
									     data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide"
									     data-responsive_offset="off" data-responsive="off" data-frames="[
                            {&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;}
                            ]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 1,
										     backgroundColor: 'rgba(0, 0, 0, 0)',
										     borderColor: 'rgba(0, 0, 0, 0)',
										     borderWidth: 0
									     }}>
									</div>
									{/* LAYER NR. 3 [ for title ] */}
									<div className="tp-caption   tp-resizeme" id="slide-901-layer-3"
									     data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
									     data-y="['top','top','top','top']" data-voffset="['260','250','200','200']"
									     data-fontsize="['120','72','72','38']" data-lineheight="['100','66','66','38']"
									     data-width="['800','600','96%','96%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     whiteSpace: 'pre-wrap',
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Teko", sans-serif',
										     textTransform: 'uppercase'
									     }}>We make IoT Easy &amp; Fast!
									</div>
									{/* LAYER NR. 4 [ for paragraph] */}
									<div className="tp-caption  tp-resizeme" id="slide-901-layer-4"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['100','80','20','-40']"
									     data-fontsize="['20','20','20','16']" data-lineheight="['30','30','30','22']"
									     data-width="['600','600','90%','90%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1500,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Poppins", sans-serif'
									     }}>
										데이터와 세상을 연결하는 기업, (주)산지
									</div>
									{/* LAYER NR. 5 [ for see all service botton ] */}
									<div className="tp-caption tp-resizeme" id="slide-901-layer-5"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['200','170','100','40']"
									     data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
									     data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{zIndex: 13, textTransform: 'uppercase'}}>
										<NavLink to={"/contactus"} className="site-button site-btn-effect">Contact Us</NavLink>
									</div>
									{/* LAYER NR. 6 [ VIDEO ICON ] */}
									{/*<div className="tp-caption" id="slide-901-layer-6" data-x="['left','left','center','center']"*/}
									{/*     data-hoffset="['420','420','0','0']" data-y="['middle','middle','middle','middle']"*/}
									{/*     data-voffset="['200','170','180','150']" data-lineheight="['0','0','0','0']"*/}
									{/*     data-width="['100','100','100','100']" data-height="['none','none','none','none']"*/}
									{/*     data-whitespace="['normal','normal','normal','normal']" data-type="text"*/}
									{/*     data-responsive_offset="on" data-frames="[*/}
                  {/*          {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},*/}
                  {/*          {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}*/}
                  {/*          ]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"*/}
									{/*     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"*/}
									{/*     style={{zIndex: 13}}>*/}
									{/*	<NavLink to={"#"} className="play-now" onClick={this.openModal}>*/}
									{/*		<i className="icon fa fa-play"/>*/}
									{/*		<span className="ripple"/>*/}
									{/*	</NavLink>*/}
									{/*</div>*/}
								</li>
								{/* SLIDE 2 */}
								<li data-index="rs-902" data-transition="fade" data-slotamount="default" data-hideafterloop={0}
								    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
								    data-thumb={require('./../../images/main-slider/slider4/homeMainUplink.jpg').default} data-rotate={0}
								    data-fstransition="fade" data-fsmasterspeed={300} data-fsslotamount={7} data-saveperformance="off"
								    data-title="Slide Title" data-param1="Additional Text" data-param2 data-param3 data-param4
								    data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
									{/* MAIN IMAGE */}
									<img src={require('./../../images/main-slider/slider4/homeMainUplink.jpg').default} alt=""
									     data-lazyload={require('./../../images/main-slider/slider4/homeMainUplink.jpg').default}
									     data-bgposition="center center" data-bgfit="cover" data-bgparallax={4} className="rev-slidebg"
									     data-no-retina/>
									{/* LAYERS */}
									{/* LAYER NR. 1 [ for overlay ] */}
									<div className="tp-caption tp-shape tp-shapewrapper " id="slide-902-layer-0"
									     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
									     data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide"
									     data-responsive_offset="off" data-responsive="off" data-frames="[
                            {&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;}
                            ]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 1,
										     backgroundColor: 'rgba(0, 0, 0, 0)',
										     borderColor: 'rgba(0, 0, 0, 0)',
										     borderWidth: 0
									     }}>
									</div>
									{/* LAYER NR. 3 [ for title ] */}
									<div className="tp-caption   tp-resizeme" id="slide-902-layer-3"
									     data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
									     data-y="['top','top','top','top']" data-voffset="['260','250','200','200']"
									     data-fontsize="['120','72','72','38']" data-lineheight="['100','66','66','38']"
									     data-width="['800','600','96%','96%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     whiteSpace: 'normal',
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Teko", sans-serif',
										     textTransform: 'uppercase'
									     }}>We make IoT Easy &amp; Fast!
									</div>
									{/* LAYER NR. 4 [ for paragraph] */}
									<div className="tp-caption  tp-resizeme" id="slide-902-layer-4"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['100','80','20','-40']"
									     data-fontsize="['20','20','20','16']" data-lineheight="['30','30','30','22']"
									     data-width="['600','600','90%','90%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1500,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Poppins", sans-serif'
									     }}>
										중소기업을 위한 클라우드 기반 통합 IoT 플랫폼 서비스  「UPLINK+」
									</div>
									{/* LAYER NR. 5 [ for see all service botton ] */}
									<div className="tp-caption tp-resizeme" id="slide-902-layer-5"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['200','170','100','40']"
									     data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
									     data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{zIndex: 13, textTransform: 'uppercase'}}>
										<NavLink to={"/contactus"} className="site-button site-btn-effect">Contact Us</NavLink>
									</div>
									{/* LAYER NR. 6 [ VIDEO ICON ] */}
									<div className="tp-caption" id="slide-901-layer-6" data-x="['left','left','center','center']"
									     data-hoffset="['420','420','0','0']" data-y="['middle','middle','middle','middle']"
									     data-voffset="['200','170','180','150']" data-lineheight="['0','0','0','0']"
									     data-width="['100','100','100','100']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{zIndex: 13}}>
										<NavLink to={"#"} className="play-now" onClick={this.openModal}>
											<i className="icon fa fa-play"/>
											<span className="ripple"/>
										</NavLink>
									</div>
								</li>
								{/* SLIDE 3 */}
								<li data-index="rs-903" data-transition="fade" data-slotamount="default" data-hideafterloop={0}
								    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default"
								    data-thumb={require('./../../images/main-slider/slider4/homeMainSion.jpg').default} data-rotate={0}
								    data-fstransition="fade" data-fsmasterspeed={300} data-fsslotamount={7} data-saveperformance="off"
								    data-title="Slide Title" data-param1="Additional Text" data-param2 data-param3 data-param4
								    data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
									{/* MAIN IMAGE */}
									<img src={require('./../../images/main-slider/slider4/homeMainSion.jpg').default} alt=""
									     data-lazyload={require('./../../images/main-slider/slider4/homeMainSion.jpg').default}
									     data-bgposition="center center" data-bgfit="cover" data-bgparallax={4} className="rev-slidebg"
									     data-no-retina/>
									{/* LAYERS */}
									{/* LAYER NR. 1 [ for overlay ] */}
									<div className="tp-caption tp-shape tp-shapewrapper " id="slide-903-layer-0"
									     data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-width="full"
									     data-height="full" data-whitespace="nowrap" data-type="shape" data-basealign="slide"
									     data-responsive_offset="off" data-responsive="off" data-frames="[
                            {&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:0,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;}
                            ]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 1,
										     backgroundColor: 'rgba(0, 0, 0, 0)',
										     borderColor: 'rgba(0, 0, 0, 0)',
										     borderWidth: 0
									     }}>
									</div>
									{/* LAYER NR. 3 [ for title ] */}
									<div className="tp-caption   tp-resizeme" id="slide-903-layer-3"
									     data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
									     data-y="['top','top','top','top']" data-voffset="['260','250','200','200']"
									     data-fontsize="['120','72','72','38']" data-lineheight="['100','66','66','38']"
									     data-width="['800','600','96%','96%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     whiteSpace: 'normal',
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Teko", sans-serif',
										     textTransform: 'uppercase'
									     }}>We make IoT Easy &amp; Fast!
									</div>
									{/* LAYER NR. 4 [ for paragraph] */}
									<div className="tp-caption  tp-resizeme" id="slide-903-layer-4"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['100','80','20','-40']"
									     data-fontsize="['20','20','20','16']" data-lineheight="['30','30','30','22']"
									     data-width="['600','600','90%','90%']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:1500,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{
										     zIndex: 13,
										     fontWeight: 500,
										     color: '#fff',
										     borderWidth: 0,
										     fontFamily: '"Poppins", sans-serif'
									     }}>
										노인&장애인을 위한 AAC기반 맞춤형 홈 IoT 서비스
									</div>
									{/* LAYER NR. 5 [ for see all service botton ] */}
									<div className="tp-caption tp-resizeme" id="slide-903-layer-5"
									     data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
									     data-y="['middle','middle','middle','middle']" data-voffset="['200','170','100','40']"
									     data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
									     data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{zIndex: 13, textTransform: 'uppercase'}}>
										<NavLink to={"/contactus"} className="site-button site-btn-effect">Contact Us</NavLink>
									</div>
									{/* LAYER NR. 6 [ VIDEO ICON ] */}
									<div className="tp-caption" id="slide-901-layer-6" data-x="['left','left','center','center']"
									     data-hoffset="['420','420','0','0']" data-y="['middle','middle','middle','middle']"
									     data-voffset="['200','170','180','150']" data-lineheight="['0','0','0','0']"
									     data-width="['100','100','100','100']" data-height="['none','none','none','none']"
									     data-whitespace="['normal','normal','normal','normal']" data-type="text"
									     data-responsive_offset="on" data-frames="[
                            {&quot;from&quot;:&quot;y:100px(R);opacity:0;&quot;,&quot;speed&quot;:2000,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:2000,&quot;ease&quot;:&quot;Power4.easeOut&quot;},
                            {&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;to&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;}
                            ]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
									     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
									     style={{zIndex: 13}}>
										<NavLink to={"#"} className="play-now" onClick={this.openSionModal}>
											<i className="icon fa fa-play"/>
											<span className="ripple"/>
										</NavLink>
									</div>
								</li>
							</ul>
							<div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}/>
						</div>
					</div>
				</div>
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='sMNRX5qj7J4'
				            onClose={() => this.setState({isOpen: false})}/>
				<ModalVideo channel='youtube' isOpen={this.state.isSionOpen} videoId='iINhKg5CDro'
				            onClose={() => this.setState({isSionOpen: false})}/>
			</>
		);
	}
};

export default Slider4;
